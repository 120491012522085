<template>
    <div>
        <div class="signout">
            <el-button type="text" @click="lognout">{{$t('userinfo.signout')}}</el-button>
        </div>
        <div class="justify-center" style="width: 100%">

            <div class="userinfo">
                <el-form label-position="left" :inline="false" :model="form" :rules="rules" ref="ruleUser">
                    <div class="justify-center">
                        <el-row class="demo-avatar demo-basic" style="margin-top: 50px;">
                            <el-col :span="24">
                                <div class="demo-basic--circle">
                                    <div class="block">
                                        <el-form-item>
                                            <el-upload class="avatar-uploader"
                                                       action="/api/file/upload"
                                                       :show-file-list="false"
                                                       :on-success="handleAvatarSuccess"
                                                       :before-upload="beforeAvatarUpload">
                                                <el-avatar :size="80" v-if="form.avatar" :src="form.avatar"></el-avatar>
                                                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                            </el-upload>
                                        </el-form-item>
                                    </div>
                                </div>
                            </el-col>
                        </el-row>
                    </div>
                    <a-row class="rowsty">
                        <a-col :xs="22" :sm="22" :md="22" :lg="22" :xl="22">
                            <el-form-item :label="$t('userinfo.nickname')" prop='name' :label-width="formLabelWidth">
                                <el-input :placeholder="$t('userinfo.nickname')" clearable autocomplete="off" v-model='form.name'/>
                            </el-form-item>
                        </a-col>
                    </a-row>
                    <a-row class="rowsty">
                        <a-col :xs="22" :sm="22" :md="22" :lg="22" :xl="22">
                            <el-form-item :label="$t('userinfo.email')" prop='email' :label-width="formLabelWidth">
                                <el-input :placeholder="$t('userinfo.email')" clearable autocomplete="off" v-model='form.email' disabled/>
                            </el-form-item>
                        </a-col>
                    </a-row>
                    <a-row class="rowsty">
                        <a-col :xs="22" :sm="22" :md="22" :lg="22" :xl="22">
                            <el-form-item :label="$t('userinfo.code')" prop='code' :label-width="formLabelWidth">
                                <el-input :placeholder="$t('userinfo.vercode')" v-model="form.code">
                                    <template slot="append">
                                        <el-button @click="sendCode" id="sendcode" type="primary"  style="color: #13CFDA" :disabled="!show">
                                            <!-- <span v-show="show">Send Code</span> -->
                                            <span v-show="show">{{$t('login.sendcode')}}</span>
                                            <span v-show="!show" class="count">{{ count }} s</span>
                                        </el-button>
                                    </template>
                                </el-input>
                            </el-form-item>
                        </a-col>
                    </a-row>
                    <a-row style="margin: 20px;margin-left: 10%;" class="rowsty">
                        <a-col :xs="22" :sm="22" :md="22" :lg="22" :xl="22" align="middle">
                            <el-button class="btn" type="primary" @click="submitUser">{{$t('friends.submit')}}</el-button>
                        </a-col>
                    </a-row>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
const TIME_COUNT = 60; //更改倒计时时间
import { updateUserById, getUserByid, logoutToken,getUpdUserCode } from "@/request/requests";
import { MessageError, MessageSuccess, getUserInfo,startLoading } from "@/util/util"
export default {
    name: "userinfo",
    data() {
        return {
            userinfo: "",
            form: this.initForm(),
            dialogFormVisible: false,
            formLabelWidth: "120px",
            rules: {
                name: [
                    // { required: true, message: 'Please enter Nickname', trigger: 'blur' },],
                    // { required: true, message: '닉네임을 입력하세요', trigger: 'blur' },],
                    { required: true, message: this.$t('home.pleaseenter')+' '+this.$t('userinfo.nickname'), trigger: 'blur' },],
                email: [
                    // { required: true, message: 'Please enter Email', trigger: 'blur' },],
                    { required: true, message: this.$t('home.pleaseenter')+' '+this.$t('home.emailaddress'), trigger: 'blur' },],
                avatar: [
                    // { required: true, message: 'Please upload your avatar', trigger: 'blur' },],
                    { required: true, message: this.$t('userinfo.uploadavatar'), trigger: 'blur' },],
                code: [
                    // { required: true, message: 'Please upload your avatar', trigger: 'blur' },],
                    { required: true, message: this.$t('home.pleaseenter')+' '+this.$t('userinfo.vercode'), trigger: 'blur' },],
            },
            show: true,
            count: '',   // 初始化次数
            timer: null,
        }
    },
    methods: {
        lognout() {
            // this.$confirmel('Are you sure you want to log out?', 'Tips', {
            this.$confirmel(this.$t('userinfo.logout'), this.$t('luckdraw.tips'), {
                // confirmButtonText: 'comfirm',
                confirmButtonText: this.$t('luckdraw.comfirm'),
                // cancelButtonText: 'cancel',
                cancelButtonText: this.$t('luckdraw.cancel'),
                type: 'warning'
            }).then(() => {
                this.logout()
            }).catch(() => {
            });
        },
        async logout() {
            sessionStorage.removeItem("userInfo");
            sessionStorage.removeItem("deadline");
            await logoutToken();
            sessionStorage.removeItem("token");
            this.$router.push({ path: "login" })
        },
        initForm() {
            return {
                userid:"",
                name: "", //姓名
                email: "", //邮箱
                code:"",//验证码
                avatar: "", //头像
            }
        },
        sendCode() {
            if (!(/^([a-zA-Z0-9_.-])+@([a-zA-Z0-9_-])+((\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6})$/.test(this.form.email))) {
                MessageError(this.$t("home.emailerror"));
                return false;
            } else {
                if (!this.timer) {
                    this.toSendCode()
                }
            }
        },
        async toSendCode() {
            let loding = startLoading()
            let res = await getUpdUserCode(this.form.email)
            if (res.status == "200") {
                loding.close()
                MessageSuccess(this.$t("login.verificationsend"))
                this.count = TIME_COUNT;
                this.show = false;
                this.timer = setInterval(() => {
                    if (this.count > 0 && this.count <= TIME_COUNT) {
                        this.count--;
                    } else {
                        this.show = true;
                        clearInterval(this.timer);  // 清除定时器
                        this.timer = null;
                    }
                }, 1000)
            } else {
                loding.close()
                MessageError(res.msg)
            }
        },
        async submitUser() {
            this.$refs.ruleUser.validate((valid) => {
                if (valid) {
                    this.operatorUser()
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        async operatorUser() {
            this.form.userid=this.userinfo.id
            let res = await updateUserById(this.form)
            if (res.status == "200") {
                this.getUserByidInfo()
                MessageSuccess(res.msg)
            } else {
                MessageError(res.msg)
            }
        },
        handleAvatarSuccess(res, file) {
            if (res.status == "200") {
                console.log(res.data)
                this.form.avatar = res.data
            } else {
                MessageError(res.msg)
            }
            console.log(file)
        },
        beforeAvatarUpload(file) {
            const isJPG = file.type === 'image/jpeg' || 'image/png';
            const isLt2M = file.size / 1024 / 1024 < 5;

            if (!isJPG) {
                this.$message.error(this.$t('userinfo.onlyjpgpng'));
            }
            if (!isLt2M) {
                this.$message.error(this.$t('userinfo.cannotexceed'));
            }
            return isJPG && isLt2M;
        },
        async getUserByidInfo() {
            let res = await getUserByid(this.userinfo.id)
            if (res.status == "200") {
                this.form = res.data
                if (this.form.avatar == "") {
                    this.form.avatar = ""
                }
            } else {
                MessageError(res.msg)
            }
        }
    },
    created() {
        let userInfo = getUserInfo()
        if (userInfo) {
            this.userinfo = JSON.parse(userInfo)
            this.getUserByidInfo()
        }
    }
}
</script>

<style scoped>
.signout{
    display: block;
    float: right;
    margin-top: 30px;
    margin-right: 50px;
}
.userinfo {
    margin: 50px;
    border-radius: 5px;
    width: 40%;
    background-color: #24292F;
    font-size: 14px;
    font-family: Microsoft YaHei;
    color: #FFFFFF;
}
.rowsty{
    display: flex;
    justify-content: center;
    width: 80%;
    margin-left: 10%;
}

.el-row {
    margin-bottom: 20px;
}

.el-col {
    border-radius: 4px;
}

.bg-purple {
    background: #d3dce6;
}

.grid-content {
    border-radius: 4px;
    min-height: 36px;
}

/deep/ .el-form-item__label{
    color: #FFFFFF;
}
.avatar-uploader-icon {
    font-size: 28px;
    color: white;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
    border: 1px solid white;
}

.avatar {
    width: 180px;
    height: 180px;
    display: block;
}

.btn{
    width: 40%;
    background: linear-gradient(-90deg, #93F5EC, #745BA7, #9A73E1);
    border: 0
}

@media screen and (max-width: 768px){
    .signout{
        display: none;
    }
    .userinfo {
        font-size: 12px;
        width: 90%;
    }
    .rowsty{
        width: 100%;
        margin-left: 0;
    }
}
</style>
